
import { computed, defineComponent, PropType, reactive } from "vue";
import { Form, FormActions } from "vee-validate";
import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import { EmailTemplateModel } from "@/models";
import Editor from "@tinymce/tinymce-vue";
import { AppGetterTypes, useStore } from "@/store";
import { routePaths } from "@/router";
import { restClient } from "@/store/actions";
import { useRouter } from "vue-router";

export default defineComponent({
    props: {
        emailTemplate: {
            type: Object as PropType<EmailTemplateModel>,
            required: true
        }
    },
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "editor": Editor
    },
    setup(props) {
        /**
         * Configure TinyMCE with Key
         * Plugins used:
         *  1. Code - enables direct editing of HTML in editor
         *     See: https://www.tiny.cloud/docs/plugins/opensource/code/
         *     See: https://www.tiny.cloud/docs/integrations/vue/
         */
        const tinyMceApiKey = process.env.VUE_APP_TINYMCE_API_KEY;

        const router = useRouter();
        const store = useStore();

        const rules = computed(
            () =>
                store.getters[AppGetterTypes.validationRules]
                    ?.emailTemplateModel
        );

        const emailTemplateTags = computed(
            () => store.getters[AppGetterTypes.emailTemplateTags]
        );

        const model: EmailTemplateModel = reactive(props.emailTemplate);

        const onSubmit = async (
            values: EmailTemplateModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            const response = await restClient.putJson<EmailTemplateModel>(
                "/api/admin/EmailTemplate",
                model
            );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
            } else {
                router.push(routePaths.emailTemplateManagementPath);
            }
        };

        const emailTagStart = "{{";
        const emailTagEnd = "}}";

        return {
            onSubmit,
            model,
            rules,
            routePaths,
            emailTemplateTags,
            tinyMceApiKey,
            emailTagStart,
            emailTagEnd
        };
    }
});
